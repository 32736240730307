import classnames from 'classnames';
import { CopyMap } from './types';

import './CustomSupportContent.css';

type ComponentPropsTypes = {
  copy: CopyMap;
  className?: string;
};

export const SupportContent = ({ copy, className }: ComponentPropsTypes) => {
  const classes = classnames('custom-support-details', className);

  return (
    <div className={classes}>
      <div className="custom-support-details__body">
        {copy['support-intro']}
      </div>
      <div className="custom-support-details__link">
        <a
          href={copy['link-contacturl']}
          target="_blank"
          rel="noopener noreferrer"
        >
          {copy['link-contact']}
        </a>
      </div>
    </div>
  );
};
