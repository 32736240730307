import { useEffect, useState } from 'react';

const PRODcbid = '488807fb-5726-42d0-b313-76bb8aa8505f';
const iFrameClassName = 'wise-embedded';
const config = {
  src: `https://consent.cookiebot.eu/uc.js`,
  id: 'Cookiebot',
  cbid: PRODcbid,
  blockingmode: 'auto',
};

const { src, id, cbid, blockingmode } = config;

export const Cookiebot = () => {
  const [isInIframe, setInIFrame] = useState<true | false | undefined>(
    undefined
  );

  useEffect(() => {
    setInIFrame(document.body.className.includes(iFrameClassName));
  }, []);

  const injectScript = () => {
    const utag = window.document.createElement('script');
    utag.id = id;
    utag.setAttribute('data-cbid', cbid);
    utag.setAttribute('data-blockingmode', blockingmode);
    utag.src = `${src}`;
    utag.type = 'text/javascript';
    window.document.head.appendChild(utag);
    return null;
  };

  useEffect(() => {
    if (isInIframe === false) {
      injectScript();
    }
  }, [isInIframe]);

  return null;
};
