import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { CustomSupportBox } from '../components/CustomSupportModal/CustomSupportBox';
import { useUTagView } from '../services/hooks';

import './NoMatch.css';

const NoMatchComponent = () => {
  useUTagView('nomatch');
  const { t } = useTranslation();
  const title = t('errorpage-title-copy');
  const supportCopy = {
    'support-intro': t('support-intro-header-copy'),
    'link-contact': t('support-contact'),
    'link-contacturl': t('url.contact-information'),
  };

  return (
    <div className="support-container">
      <Helmet title="Page Not Found" />
      <CustomSupportBox title={title} supportCopy={supportCopy} />
    </div>
  );
};

export default NoMatchComponent;
