import React from 'react';
import { CloudyIcon } from '@wise/ui-icons';
import { SupportContent } from './CustomSupportContent';
import { CopyMap } from './types';
import './CustomSupportBox.css';

type ErrorBoxProps = {
  title: string;
  supportCopy: CopyMap;
  children?: React.ReactNode[];
};

export const CustomSupportBox = ({
  title,
  supportCopy,
  children,
}: ErrorBoxProps) => (
  <div className="errorbox">
    <CloudyIcon />
    <h2 className="errorbox__title">{title}</h2>
    {children}
    <SupportContent copy={supportCopy} />
  </div>
);
