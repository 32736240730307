import { useEffect, useState } from 'react';
import { Route } from '../app/routes.types';
import { getTitleByRoute } from './eTracker.helper';
import { isProduction } from '@wise/wise-environments';

const ProdId = '4EbHKx';
const TestId = '083uhb'; // change as you wish in order to track
const iFrameClassName = 'wise-embedded';
const isProdEnvironment = isProduction('AAB');

const config = {
  trackingUri: `//code.etracker.com/code/e.js`,
  scriptId: '_etLoader',
  // As etracker does not provide tests keys you need to create an account
  // every 30 days and use that for non-prod env to check your work....
  accountNumber: isProdEnvironment ? ProdId : TestId,
  bodySelector: 'body',
  isLoggingEnabled: !isProdEnvironment,
};

const { trackingUri, scriptId, accountNumber } = config;

export const ETracker = () => {
  const [isInIframe, setInIFrame] = useState<true | false | undefined>(
    undefined
  );
  const [hash, setHash] = useState<string>();
  const pageTitle = getTitleByRoute(
    hash ? (hash.replace('#/', '') as Route) : undefined
  );

  const onHashChange = () => {
    const newHash = window.location.hash;
    if (newHash !== hash) {
      setHash(newHash);
    }
  };

  useEffect(() => {
    setInIFrame(document.body.className.includes(iFrameClassName));
  }, []);

  useEffect(() => {
    if (isInIframe === false) {
      setHash(window.location.hash);
      window.addEventListener('hashchange', onHashChange);
    }

    return () => {
      window.removeEventListener('hashchange', onHashChange);
    };
  }, [isInIframe]);

  useEffect(() => {
    if (hash) {
      reload();
    }
  }, [hash]);

  const injectScript = () => {
    const utag = window.document.createElement('script');
    utag.id = scriptId;
    utag.charset = 'UTF-8';
    utag.setAttribute('data-block-cookies', 'true');
    utag.setAttribute('data-secure-code', accountNumber);
    utag.src = `${trackingUri}`;
    utag.type = 'text/javascript';
    utag.async = true;
    window.document.head.appendChild(utag);
  };

  const trackPageview = () => {
    if (window.et_eC_Wrapper && pageTitle) {
      if (config.isLoggingEnabled) {
        console.info({ tracked: pageTitle });
      }
      window.et_eC_Wrapper({ et_et: accountNumber, et_pagename: pageTitle });
    }
  };

  const reload = () => {
    const eTrackerContainer = document.getElementById(scriptId);

    if (pageTitle) {
      window.et_pagename = pageTitle;
    }

    if (eTrackerContainer !== null) {
      trackPageview();
      return;
    }

    injectScript();
  };

  return null;
};
